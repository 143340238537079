export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Jump Start Sports',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'We believe that every child should have the chance to experience the joy of sports. Our experienced coaches are passionate about helping children develop their skills, build confidence, and learn important life lessons through sports.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
  'containers.browseEvents.FixedScheduleCard.btn': 'Register',
  'shared.constants.sportTypes.track_and_field': 'Track & Field / Running',
  'customers.settings.accounting_tab.AccountingForm.choose_service_fee_type':
    'Choose Registration Fee Type',
  'customers.settings.accounting_tab.AccountingForm.create_service_fee':
    'Create Registration Fee',
  'customers.settings.accounting_tab.AccountingForm.edit_service_fee':
    'Edit Registration Fee',
  'customers.settings.AccountingTab.archive_service_fee':
    'Archive registration fee?',
  'customers.settings.AccountingTab.are_you_sure_fee':
    'Are you sure you want to archive the registration fee {code}?',
  'customers.settings.AccountingTab.create_service_fee':
    'Create Registration Fee',
  'customers.settings.AccountingTab.service_fee': 'Registration Fee',
  'shared.checkout.OrderTotals.service_fee': 'Registration Fee',
  'shared.checkout.ServiceFeeExemptToggle.remove_service_fee':
    'Remove Registration Fee',
  'containers.reports.orderSummaryDrawer.OrderDetails.fees': 'Registration Fee',
  'shared.stores.service_fee.ServiceFeeArchivingStore.archived':
    'Registration fee archived.',
  'shared.stores.service_fee.ServiceFeeCreationStore.created':
    'Registration fee created.',
  'shared.stores.service_fee.ServiceFeeEditingStore.updated':
    'Registration fee updated.',
  'shared.constants.eventSeasons.late_fall': 'Late Fall',
  'event_mgmt.display._PurchaseButton.purchase': 'Register',
};
